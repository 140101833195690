import React from 'react';
import './header.css';

import location from '../../assets/port.react.location.png'

import social1  from '../../assets/port.react.link.png';
import social2 from '../../assets/port.react.git.png';

import stock1 from '../../assets/port.react.html.png';
import stock2 from '../../assets/symbol.png';
import stock3 from '../../assets/port.react.js.png';
import stock4 from '../../assets/Union.png';
import stock5 from '../../assets/Vector.png';
import stock6 from '../../assets/Component.png';
import stock7 from '../../assets/port.react.tensorflow.png';
import stock8 from '../../assets/port.react.python.png';
import stock9 from '../../assets/port.react.azure.png'
import stock10 from '../../assets/port.react.tableau.svg';
import stock11 from '../../assets/port.react.fast.png';


import profile from '../../assets/port_reactpro.png';


export const Header = () => {
  return (
    <div className='port__header section__padding' id='home'>
      <div className='port__header-content'>
        <h1 className='gradient__text'>
        Data Scientist - AI/ML
        </h1>
        <p>Hi, I’m Kabil. A passionate Full-Stack Developer & Data scientist from    TamilNadu, India </p>
        
        <div className='port__header-content_location'>
        <img src={location} alt='location'></img>
        </div>

        <div className='port__header-content__social'>
          <a href='https://www.linkedin.com/in/kabil-c-a-223331171/' target="_blank" rel="noreferrer">
           <img src={social1} alt='social1'></img>
          </a>
          <a href=' https://github.com/Kabilduke' target="_blank" rel="noreferrer">
           <img src={social2} alt='social2'></img>
          </a>     
        </div>

        <div className='port__header-profile'>
            <img src={profile} alt='profile'></img>
        </div>
        <div className='port__header-content__stock'>
        <p>Tech Stocks</p>
          <img src={stock1} alt='stock1'></img>
          <img src={stock2} alt='stock2'></img>
          <img src={stock3} alt='stock3'></img>
          <img src={stock4} alt='stock4'></img>
          <img src={stock5} alt='stock5'></img>
          <img src={stock6} alt='stock6'></img>
          <img src={stock7} alt='stock7'></img>
          <img src={stock8} alt='stock8'></img>
          <img src={stock9} alt='stock9'></img>
          <img src={stock10} alt='stock10'></img>
          <img src={stock11} alt='stock11'></img>
        </div>
      </div>
    </div>
  )
}

export default Header;