import React from 'react';
import './cta.css';

export const Cta = () => {
  return (
    <div>
       cta
        </div>
  )
}

export default Cta;