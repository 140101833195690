import React from 'react';
import './contact.css';

import mail from '../../assets/port.mail.png';
import location from '../../assets/port.location.png';
import message from '../../assets/port.Message.png'

export const Contact = () => {
  return (
  <div className='port__contact section__margin section__padding' id='contact'>
      <h1>Contact</h1>
      <div className='port__contact-quote'>
        <h2>Let's Connect!</h2>
        <p>Don't be a stranger! Say hello and let's collaborate</p>
      </div>
   <div className='port__contact-logo'>
      <div className='port__contact-mail'>
        <img src={mail} alt='Mail'></img>
        <p>Ca.kabil12@gmail.com</p>
      </div>

      <div className='port__contact-location'>
        <img src={location} alt='Location'></img>
        <p>India,TamilNadu </p>
      </div>
        
      <div className='port__contact-Message'>
        <img src={message} alt='Message'></img>
        <p>Kabil C.A</p>
      </div>      
   </div>

  </div>
  )
}


export default Contact;