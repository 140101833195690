import React from "react";
import "./portfolio.css";

import port1 from "../../assets/Project1.png";
import port2 from "../../assets/Project2.png";
import port3 from "../../assets/Project3.png";
import port4 from "../../assets/Project4.png";

import git from "../../assets/git.png";

export const Portfolio = () => {
  return (
    <div className="port__folio section__margin" id="portfolio">
      <div className="port__folio-title">
        <h1>Portfolio</h1>
      </div>

      <div className="port__folio-section01">
        <div className="port__folio-content">
          <div className="port__folio-left">
            <img src={port1} alt="port1"></img>
          </div>
          <div className="port__folio-description_right">
            <h2>Music Player App</h2>
            <p>
            Created a user-friendly music player app for Android using Android Studio, where I employed XML to design the app's frontend, defining how the user interface looks. Java was used to handle music playback, user interactions, and manage the app's overall functionality. Additionally, I integrated Firebase to enable push notifications, enhancing the app's communication capabilities. This allows users to receive timely updates and notifications while using the music player. The combination of XML, Java, Android Studio, and Firebase ensures a smooth and engaging experience, making the music player intuitive and responsive for users on Android devices.
            </p>
          </div>
        </div>
        <div className="port__folio-link01">
          <div className="port__folio-view">
            <a href="https://drive.google.com/file/d/1W2vEq0MNWtyyoqpuPH369S3wqWTOiJP9/view?usp=drive_link">view</a>
          </div>

          <div className="port__folio-git">
            <img src={git} alt="git"></img>
            <a href="https://github.com/Kabilduke/Music-X">Git code</a>
          </div>
        </div>
      </div>


      <div className="port__folio-section02">
        <div className="port__folio-content">
          <div className="port__folio-description_left">
            <h2>Generative AI for Image captioning</h2>
            <p>
            A deep learning model was created for image detection and caption generation using Python, CNN (Convolutional Neural Network), LSTM (Long Short-Term Memory), and TensorFlow. The model, implemented in Google Colab (CoLab), is trained to accurately recognize and describe images. To enrich captions, an open-source API connects to advanced language models like ChatGPT-4 from OpenAI. This ensures informative and ethical content. The system aims for engaging and unbiased captions, promoting user engagement and creating a more inclusive experience for everyone utilizing the image caption feature.
            </p>
          </div>
          <div className="port__folio-right">
            <img src={port2} alt="port2"></img>
          </div>
        </div>

        <div className="port__folio-link02">
          <div className="port__folio-view">
            <a href="https://drive.google.com/drive/folders/1NCm32Y-sr3ylY_2Y53jhugyTT5Q7XFlC?usp=drive_link">view</a>
          </div>
          <div className="port__folio-git">
            <img src={git} alt="git"></img>
            <a href="https://github.com/Kabilduke/Generative-AI-for-Image-Captioning">Git code</a>
          </div>
        </div>
      </div>

      <div className="port__folio-section01">
        <div className="port__folio-content">
          <div className="port__folio-left">
            <img src={port3} alt="port3"></img>
          </div>
          <div className="port__folio-description_right">
            <h2>UI & UX Design</h2>
            <p>
            Created and delivered 15+ UI/UX designs for clients using a combination of HTML, CSS, Canva, and Figma. These designs demonstrate versatility in employing industry-standard tools and coding languages across a range of projects for clients. Using HTML and CSS demonstrates my dedication to creating digital interfaces that are both aesthetically beautiful and totally functional. In order to improve user happiness, my strategy places a high priority on user-centric principles and guarantees visually appealing layouts and simple navigation. The portfolio exhibits flexibility, originality, and a commitment to producing powerful and customer-focused UI/UX concepts.
            </p>
          </div>
        </div>
        <div className="port__folio-link01">
          <div className="port__folio-view">
            <a href="https://www.figma.com/@kabil">view</a>
          </div>
          <div className="port__folio-git">
            <img src={git} alt="git"></img>
            <a href="https://github.com/Kabilduke/Code">Git code</a>
          </div>
        </div>
      </div>

      <div className="port__folio-section02">
        <div className="port__folio-content">
          <div className="port__folio-description_left">
            <h2>Multimodel Chat-BOT</h2>
            <p>
            Developed a Multitasking  Assistant with the GPT-3.5 Turbo model from OpenAI. Users are able to ask questions about languages and converse in natural language with ease. The assistant fully interprets and answers user inquiries thanks to GPT-3.5 Turbo. The front page has an easy-to-use interface thanks to its HTML and CSS design. When users ask inquiries, the chatbot quickly and accurately translates them into other languages after being setup with an OpenAI API key. It may identify commands such as "quit," "bye," "exit," or "stop" to ensure a seamless user interface.
            </p>
          </div>
          <div className="port__folio-right">
            <img src={port4} alt="port4"></img>
          </div>
        </div>

        <div className="port__folio-link02">
          <div className="port__folio-view">
            <a href="#home">view</a>
          </div>
          <div className="port__folio-git">
            <img src={git} alt="git"></img>
            <a href="https://github.com/Kabilduke/Chatbot-API-key">Git code</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
