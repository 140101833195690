import React from "react";

import { About,Blog,Portfolio,Contact,Footer,Header } from "./Container";
import { Cta,Navibar} from "./Component";
import './App.css';
import './index.css';

const App = ()=>{
return(
    <div className="App">
     <div className="gradient__bg">
        <Navibar></Navibar>
        <Header></Header>
     
     <About></About>
     <Portfolio></Portfolio>
     <Contact></Contact>
     <Footer></Footer>
    </div>
     <Cta></Cta>
     <Blog></Blog>
    </div>
)
}

export default App;