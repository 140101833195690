import React from 'react';
import './about.css';


import Anime01 from '../../assets/port.react.01.png';
import Anime02 from '../../assets/port.react.02.png';
import Anime03 from '../../assets/port.react.03.png'; 



 const About = () => {

  
  return (
    <div className='port__about section__padding section__margin' id='about'>
      <div className='port__about-title'>
         <h1>About Me</h1>
      </div>
        <div className='port__about-anime'>

         <div className='port__about-01'>
          <img src={Anime01} alt='Anime01'></img>
         </div>

         <div className='port__about-02'>
          <img src={Anime02} alt='Anime02'></img>
         </div>

         <div className='port__about-03'>
          <img src={Anime03} alt='Anime03'></img>
         </div>

       </div> 
       <div className='port__about-me'>
          <p>Hi, I’m Kabil. A passionate 
           Front-end Web Developer & Data Scientist  based in TamilNadu, India</p>
        </div> 
        <div className='port__about-content'>
          <p>As a dedicated Front-end Web Developer and Data Scientist, I excel in merging creativity with data insights. I focus on creating attractive and interactive websites, using skills like HTML, CSS, JavaScript, and data science tools. Driven by a strong curiosity for innovative solutions, my tech journey is all about learning. Whether it's coding engaging interfaces or exploring data analytics, I enthusiastically take on challenges. Always eager to learn and adapt, my goal is to use my expertise in both front-end development and data science to make meaningful and user-friendly digital experiences.</p>
        </div>
        <div className='port__about-button'>
          <a href='https://drive.google.com/file/d/1D7M10NqDGgEdTBGVRlWubIYkt18qOaWV/view?usp=drive_link'>
           <center>Download CV</center> 
          </a>
        </div>
  </div>  
  )
}


export default About;