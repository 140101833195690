import React from 'react';
import './footer.css';

export const Footer = () => {
  return (
    <div className='port__foo section__padding section__margin' >
      <button className="bn">"The greatest rewards come from pushing your limits and finding joy in the process."</button>
    </div>
  )
}

export default Footer;